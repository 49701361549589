import React from "react"

import { Link } from "gatsby"

import { StyledAboutUs } from "./AboutUs.styled"

import H1 from "../H1/H1"
import H2 from "../H2/H2"
import logo from "../../assets/images/logox2.svg"
import Button from "../Button/Button.styled"

const AboutUs = ({ withButton }) => {
  return (
    <StyledAboutUs>
      <div>
        <H1>O NAS</H1>
        <H2 gold>Warszawa</H2>
        <p>
          Nasze oferty
          opracowywane są przy udziale osób zarządzających publicznym stołecznym
          szpitalem, przychodnią, właścicieli wielu prywatnych gabinetów.
          Posiadamy merytoryczne wsparcie lekarzy niemalże każdej specjalizacji.
          Wykorzystując spuściznę relacji branżowych i doświadczenia MedInvest
          świadczy szeroki wachlarz usług doradczych i consultingowych na rzecz
          najważniejszych dostawców usług medycznych, grup inwestycyjnych oraz
          deweloperów w całym kraju, a także poza jego granicami.
        </p>
        {withButton && (
          <Link to="/onas">
            <Button>Czytaj więcej</Button>
          </Link>
        )}
      </div>

      <img src={logo} alt="MedInvest" className="logoImage" />
    </StyledAboutUs>
  )
}

export default AboutUs
